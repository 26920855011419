export enum Estado{
    Nuevo = 1, // No ha sido abierto ni revisado.
    Pendiente = 2, // Ha sido revisado.
    Despachado = 3, // Es despachado al Solicitante.
    Recibido = 4, // Cuando un pedido es recibido por el Solicitante.
}
export enum TipoUsuario{
    Distribuidor = 1,
    Productor = 2,
    Cliente = 3,
    Web = 4
}

export enum TipoEmpresa{
    Ninguno = 0,
    Distribuidora = 1,
    Productora = 2,
    Cliente = 3
}

export enum Privilegio{

}