import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, filter, finalize, switchMap, take, first } from 'rxjs/operators';

import { AuthenticationService } from './../services/authentication.service';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorFilter implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private auth: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: any) => {
                if (error.status === 401 && !request.url.includes('/refresh')) {
                    return this.handle401Error(request, next);
                }
                return throwError(error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        if (request.url.includes('auth/refreshtoken')) {
            this.isRefreshingToken = false;
            return of(<any>this.auth.logout());
        }
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            return this.auth.refreshToken().pipe(switchMap(token => {
                if (token) {
                  request = request.clone({
                    setHeaders: {Authorization: 'Bearer ' + token},
                    url: request.url.replace('/authorize', '')
                });
                    return next.handle(request);
                }
                return of(<any>this.auth.logout());
            }),
            catchError(err => {
                this.auth.logout();
                return throwError(err.error);
            }),
            finalize(() => {
                this.isRefreshingToken = false;
            }));
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject
                .pipe(filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(request);
                }));
        }
    }
}