import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home/home.component';
import { PolicyComponent } from './home/policy/policy.component';
import { DashboardComponent } from './private/dashboard/dashboard.component';
import { ListadoComponent } from './private/listado/listado.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'Home',
    component: HomeComponent
  },
  {
    path:'politica-privacidad',
    component: PolicyComponent
  },
  {
    path: 'privado/dashboard',
    component: DashboardComponent
  },
  {
    path: 'privado/listado',
    component: ListadoComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
