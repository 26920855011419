import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';
import { Filtro, Empresa, EmpresaProveedor } from 'src/app/global/model/Model';
import { DateFormatPipe } from 'src/app/global/Services/date-format.pipe';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.css']
})
export class FiltroComponent implements OnInit, OnDestroy {
  @Output() talk: EventEmitter<any> = new EventEmitter<any>();
  Anhos: number[];
  Semanas: string[];
  Meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  Filtro = new Filtro();
  Empresa = new Empresa();
  FiltroAnho: number;
  FiltroMes: string;
  FiltroSemana: string;
  FiltroEmpresa: number = 0;
  FiltroMiEmpresa: number;

  anhoActual: number;
  mesActual: number;
  semanaActual: string;

  Tipo: string = "Vendidas";

  Proveedores: EmpresaProveedor[] = [];
  Compradores: EmpresaProveedor[] = [];

  subscriptionFiltro: Subscription;
  subscriptionEmpresa: Subscription;
  subscriptionProveedores: Subscription;
  subscriptionCompradores: Subscription;

  constructor(private pipe: DateFormatPipe,
    private session: SessionService) { }

  ngOnInit() {
    this.subscriptionEmpresa = this.session.EmpresaActual.subscribe(empresa => {
      if (empresa != null) {
        this.Empresa = empresa;
        this.Tipo = this.Empresa.Tipo === 3 ? 'Compradas' : 'Vendidas';
        this.FiltroMiEmpresa = empresa.Id;

        this.subscriptionFiltro = this.session.Filtro.subscribe(filtro => {
          if (filtro) {
            this.Filtro = filtro;
            if (this.pipe.getDayDifference(this.Filtro.FechaInicio, this.Filtro.FechaTermino) > 7) {
              this.FiltroSemana = 'Mes Completo';
            } else {
              this.FiltroSemana = this.pipe.getSemanaDeFechasEnteras(this.Filtro.FechaInicio, this.Filtro.FechaTermino);
            }
            this.FiltroAnho = this.Filtro.Anho;;
            this.FiltroMes = this.Filtro.Mes;
          }
        });
        this.setFiltroDefault();
        this.setFiltroActual();
      }
    });

    this.subscriptionProveedores = this.session.Proveedores.subscribe(proveedores => {
      this.Proveedores = proveedores;
    });

    this.subscriptionCompradores = this.session.Compradores.subscribe(compradores => {
      this.Compradores = compradores;
    });
  }

  ngOnDestroy(){
    this.subscriptionCompradores.unsubscribe();
    this.subscriptionEmpresa.unsubscribe();
    this.subscriptionFiltro.unsubscribe();
    this.subscriptionProveedores.unsubscribe();
  }

  setFiltroDefault() {
    this.Filtro.IdEmpresaDespacho = this.session.getEmpresaActual.Id;
    this.Filtro.Anho = parseInt(this.pipe.getYear(Date.now()));
    this.Filtro.Mes = this.Meses[this.pipe.getMonth(Date.now()) - 1]
    var primerDia = this.pipe.transform(new Date(this.Filtro.Anho, this.Meses.findIndex(i => i === this.Filtro.Mes), 1));
    var ultimoDia = this.pipe.transform(new Date(this.Filtro.Anho, this.Meses.findIndex(i => i === this.Filtro.Mes), this.getDay()));
    this.Filtro.FechaInicio = this.pipe.getFechaEntera(primerDia);
    this.Filtro.FechaTermino = this.pipe.getFechaEntera(ultimoDia);
    this.session.setFiltro(this.Filtro);
  }

  tipoSeleccionado(value: string) {
    this.Tipo = this.Filtro.Tipo = value;
    this.FiltroEmpresa = 0;
    this.empresaSeleccionada();
  }

  getSemanas() {
    this.Semanas = [];
    let ultimoDiaMes = this.getDay();
    for (let index = 1; index <= ultimoDiaMes; index++) {
      let tempDate = new Date(this.FiltroAnho, this.Meses.findIndex(p => p === this.FiltroMes), index);
      if (tempDate.getDay() === 0) {
        var domingo = new Date(tempDate);
        var lunes = new Date(tempDate.setDate(tempDate.getDate() - 6));
        var semana = this.pipe.transform(lunes) + " - " + this.pipe.transform(domingo);
        this.Semanas.push(semana);
      }
      if (index === ultimoDiaMes) {
        let tempDate = new Date(this.FiltroAnho, this.Meses.findIndex(p => p === this.FiltroMes), index);
        if (tempDate.getDay() != 0) {
          var ultimoLunes = new Date(tempDate.setDate(tempDate.getDate() - tempDate.getDay() + 1));
          var domingoSiguiente = new Date(tempDate.setDate(tempDate.getDate() - tempDate.getDay() + 1 + 6));
          semana = this.pipe.transform(ultimoLunes) + " - " + this.pipe.transform(domingoSiguiente);
          this.Semanas.push(semana);
        }
      }
    }

    this.FiltroSemana = 'Mes Completo';
    var primerDia = this.pipe.transform(new Date(this.Filtro.Anho, this.Meses.findIndex(i => i === this.Filtro.Mes), 1));
    var ultimoDia = this.pipe.transform(new Date(this.Filtro.Anho, this.Meses.findIndex(i => i === this.Filtro.Mes), this.getDay()));

    this.Filtro.FechaInicio = this.pipe.getFechaEntera(primerDia)
    this.Filtro.FechaTermino = this.pipe.getFechaEntera(ultimoDia)
    // this.session.setFiltro(this.Filtro);
    this.semanaSeleccionada();
  }


  empresaSeleccionada() {
    switch (this.Tipo) {
      case 'Compradas': {
        this.Filtro.IdEmpresaRecepcion = this.FiltroEmpresa;
        this.Filtro.IdEmpresaDespacho = this.FiltroMiEmpresa;
        break;
      }
      case 'Vendidas': {
        this.Filtro.IdEmpresaDespacho = this.FiltroEmpresa;
        this.Filtro.IdEmpresaRecepcion = this.FiltroMiEmpresa;
      }
    }
    // this.session.setFiltro(this.Filtro);
    this.semanaSeleccionada();
  }

  setFiltroActual() {
    this.Anhos = [];
    this.anhoActual = parseInt(this.pipe.getYear(Date.now()));
    this.mesActual = parseInt(this.pipe.getMonth(Date.now()));
    const anhoInicio = 2018;

    for (let a = this.anhoActual; a >= anhoInicio; a--) {
      this.Anhos.push(a);
    }

    this.FiltroAnho = this.Filtro.Anho;
    if(this.Filtro.Anho)
      this.FiltroAnho = this.anhoActual

    this.tipoSeleccionado('Vendidas');
    this.getSemanas();
  }

  anhoSeleccionado() {
    this.Filtro.Anho = this.FiltroAnho;
    this.getSemanas();
  }

  mesSeleccionado() {
    if (this.FiltroSemana == 'Mes Completo') {
      var primerDia = this.pipe.transform(new Date(this.Filtro.Anho, this.Meses.findIndex(i => i === this.Filtro.Mes), 1));
      var ultimoDia = this.pipe.transform(new Date(this.Filtro.Anho, this.Meses.findIndex(i => i === this.Filtro.Mes), this.getDay()));

      this.Filtro.FechaInicio = this.pipe.getFechaEntera(primerDia)
      this.Filtro.FechaTermino = this.pipe.getFechaEntera(ultimoDia)
    } else {
      this.Filtro.FechaInicio = this.pipe.getFechaEntera(this.FiltroSemana.split(' - ')[0]);
      this.Filtro.FechaTermino = this.pipe.getFechaEntera(this.FiltroSemana.split(' - ')[1]);
    }
    this.Filtro.Mes = this.FiltroMes;
    this.getSemanas()
  }

  semanaSeleccionada() {
    if (this.FiltroSemana == 'Mes Completo') {
      var primerDia = this.pipe.transform(new Date(this.Filtro.Anho, this.Meses.findIndex(i => i === this.Filtro.Mes), 1));
      var ultimoDia = this.pipe.transform(new Date(this.Filtro.Anho, this.Meses.findIndex(i => i === this.Filtro.Mes), this.getDay()));

      this.Filtro.FechaInicio = this.pipe.getFechaEntera(primerDia)
      this.Filtro.FechaTermino = this.pipe.getFechaEntera(ultimoDia)
    } else {

      this.Filtro.FechaInicio = this.pipe.getFechaEntera(this.FiltroSemana.split(' - ')[0]);
      this.Filtro.FechaTermino = this.pipe.getFechaEntera(this.FiltroSemana.split(' - ')[1]);
    }
    this.session.setFiltro(this.Filtro);
  }

  getDay() {
    return this.pipe.getDay(new Date(this.Filtro.Anho, this.Meses.findIndex(p => p == this.Filtro.Mes) + 1, 0));
  }
}
