import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import { first } from 'rxjs/operators';
import { Despacho, Pedido, ProductoEmpresa } from 'src/app/global/model/Model';

@Component({
  selector: 'app-consolidado',
  templateUrl: './consolidado.component.html',
  styleUrls: ['./consolidado.component.css']
})
export class ConsolidadoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  displayedColumns: string[] = ['Id', 'Nombre', 'Solicitado', 'Pendiente'];
  dataSource: MatTableDataSource<any>;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  Pedidos: Pedido[];
  Despachos: Despacho[];
  Stock: ProductoEmpresa[];

  constructor(private session: SessionService,
    private service: ApiService) { }

  ngOnInit() {
    this.session.EmpresaActual.subscribe(empresa => {
      if (empresa != null) {
        var pathSolicitados = `dashboard/ItemConsolidado/${empresa.Id}`;

        this.service.Get(pathSolicitados, true)
          .pipe(first())
          .subscribe(pedidos => {
            this.Pedidos = pedidos;
            this.dataSource = new MatTableDataSource(this.Pedidos);
            this.isLoadingResults = false;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.resultsLength = this.Pedidos.length;
          });
      }
    });


  }
}
