import { Component, Input, OnInit } from '@angular/core';
import { Empresa } from 'src/app/global/model/Model';

@Component({
  selector: 'app-despacho',
  templateUrl: './despacho.component.html',
  styleUrls: ['./despacho.component.css']
})
export class DespachoComponent implements OnInit {
  @Input() Seleccionado: any;
  @Input() Empresa: Empresa;

  constructor() { }

  ngOnInit() {
  }

}
