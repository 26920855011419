import { Component, OnInit } from '@angular/core';
import { } from '@angular/common/http';
import { DatosConexion, Empresa, Usuario, UsuarioEmpresa } from 'src/app/global/model/Model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SessionService } from 'src/app/services/session.service';
import { ApiService } from 'src/app/services/api.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  Cargando: boolean;
  Usuario: Usuario;
  Empresa: Empresa = new Empresa();
  LoginModel: DatosConexion;
  constructor(private auth: AuthenticationService,
    private session: SessionService,
    private service: ApiService,
    private router: Router) {
      this.LoginModel = new DatosConexion();
  }

  ngOnInit() {
    this.auth.currentUser.subscribe(user => {
      this.Usuario = user;
      if(user){

        var empresa = this.session.getEmpresaActual
      this.empresaSeleccionada(empresa != null ? empresa : this.Usuario.UsuarioEmpresa[0].Empresa);}

    });
  }

  logOut() {
    this.auth.logout();
  }

  empresaSeleccionada(empresa: Empresa) {
    this.Cargando = true;
    this.Empresa = empresa;
    this.session.setEmpresaActual(empresa);

    var path = [];
    path.push(`administracion/empresaproveedor/`)
    path.push(`Includes=Proveedor,EmpresaSolicita;`);
    path.push(`Eliminado=${false};`);

    this.service.Get(`${path.join("")}IdEmpresaSolicita=${this.Empresa.Id};`, true)
      .pipe(first())
      .subscribe(proveedores => {
        this.session.setProveedores(proveedores);
      });

      this.service.Get(`${path.join("")}IdProveedor=${this.Empresa.Id};`, true)
      .pipe(first())
      .subscribe(compradores => {
        this.session.setCompradores(compradores);
      });

    this.Cargando = false;
  }

  OnLoginRequest() {
    this.Cargando = true;
    this.auth.login(this.LoginModel)
    .pipe(first())
      .subscribe(data => {
        document.getElementById('closeLoginModal').click();
        this.router.navigate(['/privado/dashboard'])
        this.Cargando = false;
      });
  }
}
