import { Component, OnChanges, OnInit } from '@angular/core';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-to-pdf-factura',
  templateUrl: './to-pdf-factura.component.html',
  styleUrls: ['./to-pdf-factura.component.css']
})
export class ToPdfFacturaComponent implements OnInit, OnChanges {
  Seleccionado: any;
  Tipo: string;
  constructor(private session: SessionService,) { }

  ngOnInit() {
    this.session.Seleccionado.subscribe(seleccionado => {
      this.Seleccionado = seleccionado;
    });
  }

  ngOnChanges() {
  }

  toPDF() {
    window.scrollTo(0, 0);
    // document.getElementById("navBarExportToo").innerHTML = document.getElementById("navBarExport").innerHTML;
    var dataToExport = document.getElementById('ToExport');
      var delayInMilliseconds = 2000; //2 second
    setTimeout((function() {
      html2canvas(dataToExport).then(canvas => {
     
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jsPDF('p', 'mm', 'a4', true); // A4 size page of PDF  
        var imgWidth = pdf.internal.pageSize.getWidth() - 20;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var margenY = 5;
        var margenX = 10;
        pdf.addImage(contentDataURL, 'PNG', margenX, margenY, imgWidth, imgHeight, undefined)
        pdf.save('Indicadores - ' + this.TipoDocumento + '.pdf'); // Generated PDF   
        document.getElementById('closeExportButton').click();
        this.Cargando = false;
      });
    }).bind(this), delayInMilliseconds);
    ////////////////////////////////////////////////////////////////////////////
    //------// "jspdf": "^1.3.5",
    // window.scrollTo(0, 0);
    // var dataToExport = document.getElementById('ToExport');
    // var delayInMilliseconds = 2000; //2 second
    // setTimeout((function () {
    //   var specialElementHandlers = {
    //     '#editor': function (element, renderer) {
    //       return true;
    //     }
    //   };

    //   let tipo = this.Seleccionado.IdEmpresaOrigen === this.session.EmpresaActualSubject.value.Id ? 'Venta' : 'Compra';

    //   var doc = new jsPDF('p', 'pt', 'letter');
    //   doc.fromHTML(
    //     dataToExport,
    //     15,
    //     15,
    //     {
    //       'width': 180,
    //       'elementHandlers': specialElementHandlers
    //     }, function (tipo) { doc.save('Archivo qliao') });
    //   this.Cargando = false;
    // }).bind(this), delayInMilliseconds);
  }

 
}
