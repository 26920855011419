import { Component, Input, OnInit } from '@angular/core';
import { Empresa } from 'src/app/global/model/Model';

@Component({
  selector: 'app-recepcion',
  templateUrl: './recepcion.component.html',
  styleUrls: ['./recepcion.component.css']
})
export class RecepcionComponent implements OnInit {
  @Input() Seleccionado: any;
  @Input() Empresa: Empresa;

  constructor() { }

  ngOnInit() {
  }

}
