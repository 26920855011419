import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as Global from '../global/model/global';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private httpPostOptions =
    {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json'
          }),
      withCredentials: true,
    };

  constructor(private http: HttpClient) {
  }

  Get(path: string, authorize = false) {
    return this.http.get<any>(`${Global.ApiUrl + path + (authorize ? "/authorize" : "")}`)
      .pipe(map(data => {
        return data;
      }))
  }

  Post(payload: any, path: string, authorize = false) {
    
    return this.http.post<any>(`${Global.ApiUrl + path + (authorize ? "/authorize" : "")}`, payload, this.httpPostOptions)
  }
}
