import { ReturnStatement } from '@angular/compiler';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { first } from 'rxjs/operators';
import { snackConfig } from 'src/app/global/model/global';
import { Empresa } from 'src/app/global/model/Model';
import { DateFormatPipe } from 'src/app/global/Services/date-format.pipe';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']
})
export class PagoComponent implements OnInit, OnChanges {
  @Input() Seleccionado: any;
  @Input() Empresa: Empresa;

  NuevoDetalle: any;
  TotalAcumulado = 0;
  isLoading = false;
  SaldoPendiente = 0;


  constructor(private service: ApiService,
    private snack: MatSnackBar,
    private pipe: DateFormatPipe) { }

  ngOnInit() {
    this.NuevoDetalle = {
      IdPago: this.Seleccionado.IdPago,
      TipoPago: 0,
      Estado: 1
    }
  }

  ngOnChanges() {
    if(this.Seleccionado.IdPago <= 0){
    if (!this.Seleccionado.Pago) {
      this.Seleccionado.Pago = {
        IdFactura: this.Seleccionado.IdFactura,
        Detalles: [],
        Estado: 1
      }
      this.TotalAcumulado = 0;
    }else{
      this.TotalAcumulado = this.Seleccionado.Pago.Detalles.reduce((sum, a) => { return sum + a.Monto }, 0)
      this.SaldoPendiente = this.Seleccionado.Factura.PrecioTotal - this.TotalAcumulado;
    }}
  }

  agregarPago() {
    this.SaldoPendiente = this.Seleccionado.Factura.PrecioTotal - this.TotalAcumulado;
    if (this.SaldoPendiente > 0 && this.NuevoDetalle.Monto > this.SaldoPendiente) {
      this.snack.open('El monto del abono no puede ser superior al monto pendiente.',
        '', snackConfig
      );
    } else {
      this.isLoading = true;
      if (this.Seleccionado.IdPago == 0) {
        let path = [];
        path.push(`administracion/pago/`);
        this.service.Post(this.Seleccionado.Pago, path.join(""), true)
          .pipe(first())
          .subscribe(pago => {
            this.Seleccionado.Pago = pago;
            this.Seleccionado.Pago.Detalles = [];
            this.NuevoDetalle.IdPago = this.Seleccionado.IdPago = pago.IdFactura;
            this.agregarDetallePago();
          }, error => {
            this.snack.open('No fue posible crear el Pago',
              '', snackConfig
            );
          });
      } else {
        this.agregarDetallePago();
      }

    }
  }

  agregarDetallePago() {

    if(this.NuevoDetalle.Monto <= 0) return;

    let Pagado = false;
    if(this.Seleccionado.Pago.Detalles.reduce((sum, a) => { return sum + a.Monto }, 0) + this.NuevoDetalle.Monto === this.Seleccionado.Factura.PrecioTotal)
      Pagado = true;
    if(this.TotalAcumulado > 0 && this.SaldoPendiente <= 0)
      Pagado = true;


    let path = [];
    path.push(`administracion/detallepago/`);
    path.push(`${Pagado}`)
    this.service.Post(this.NuevoDetalle, path.join(""), true)
      .pipe(first())
      .subscribe(detalle => {
        this.Seleccionado.Pago.Detalles.push(detalle)
        this.NuevoDetalle = {
          IdPago: this.Seleccionado.IdPago,
          TipoPago: 0,
        }
        this.TotalAcumulado = this.Seleccionado.Pago.Detalles.reduce((sum, a) => { return sum + a.Monto }, 0);
        this.SaldoPendiente = this.Seleccionado.Factura.PrecioTotal - this.TotalAcumulado;
        
        this.Seleccionado.Pago.Estado = detalle.Pago ? detalle.Pago.Estado : this.Seleccionado.Pago.Estado;
        this.isLoading = false;
      }, error => {
        this.snack.open('No fue posible crear el Detalle de Pago',
          '', snackConfig
        );
        this.isLoading = false;
      });
  }
}
