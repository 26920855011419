import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return super.transform(value, "dd/MM/yyyy");
  }
  getYear(value: any, args?: any): any {
    return super.transform(value, "yyyy");
  }
  getMonth(value: any, args?: any): any {
    return super.transform(value, "M");
  }
  fechaSAP(value: any, args?: any): any {
    return super.transform(value, "yyyy-MM-dd");
  }

  getDay(value: any, args?: any): number {
    return parseInt(super.transform(value, "dd"));
  }

  getFechaEntera(value: any, args?: any): number{
    var year = value.split('/')[2];
    var month = value.split('/')[1]-1;
    var day = value.split('/')[0];
    
    value = new Date(year, month, day);
    return parseInt(super.transform(value, "yyyyMMdd"));
  }

  getFechaDeFechaEntera(value: any, args?:any): string{
    value = new Date(value.substr(0, 4), value.substr(4, 2) - 1, value.substr(6, 2));
    return super.transform(value, "dd/MM/yyyy");
  }

  getSemanaDeFechasEnteras(fechaInicio: any, fechaTermino: any, args?: any): string{
    fechaInicio = fechaInicio + "";
    fechaInicio = new Date(fechaInicio.substr(0, 4), fechaInicio.substr(4, 2) - 1, fechaInicio.substr(6, 2));
    fechaTermino = fechaTermino + "";
    fechaTermino = new Date(fechaTermino.substr(0, 4), fechaTermino.substr(4, 2) -1 , fechaTermino.substr(6, 2));
    return `${super.transform(fechaInicio, "dd/MM/yyyy")} - ${super.transform(fechaTermino, "dd/MM/yyyy")}`;
  }

  getDayDifference(fechaInicio: any, fechaTermino: any, args?: any): number{
    fechaInicio = fechaInicio + "";
    fechaInicio = new Date(fechaInicio.substr(0, 4), fechaInicio.substr(4, 2) - 1, fechaInicio.substr(6, 2));
    fechaTermino = fechaTermino + "";
    fechaTermino = new Date(fechaTermino.substr(0, 4), fechaTermino.substr(4, 2) -1 , fechaTermino.substr(6, 2));
    
    var Difference_In_Time = fechaTermino.getTime() - fechaInicio.getTime(); 
    return Difference_In_Time / (1000 * 3600 * 24); 
  }
}
