import { HttpHeaders } from '@angular/common/http';
import { MatPaginatorIntl, MatSnackBarConfig } from '@angular/material';
import * as Highcharts from 'highcharts';
export class Global {

}
//API Localhost
//export const ApiUrl = 'http://localhost:53031/api/';

//Api Desa
export const ApiUrl = "https://fincapp.cl/api/";

export const httpPostOptions =
{
  headers:
    new HttpHeaders(
      {
        'Content-Type': 'application/json'
      }),
  withCredentials: true,
};

export interface DialogData {
  Titulo: string;
  Mensaje: string;
  Salir: boolean;
}

export const snackConfig: MatSnackBarConfig = {
  horizontalPosition: 'right',
  verticalPosition: 'top',
  duration: 5000
};

export const exporting = {
  buttons: {
    contextButton: {
      menuItems: ["printChart", "downloadPDF", "table"],
      symbol: 'download'
    }
  }
}
Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
  var path = [
      // Arrow stem
      'M', x + w * 0.5, y,
      'L', x + w * 0.5, y + h * 0.7,
      // Arrow head
      'M', x + w * 0.3, y + h * 0.5,
      'L', x + w * 0.5, y + h * 0.7,
      'L', x + w * 0.7, y + h * 0.5,
      // Box
      'M', x, y + h * 0.9,
      'L', x, y + h,
      'L', x + w, y + h,
      'L', x + w, y + h * 0.9
  ];
  return path;
};



export const lang = {
  downloadPDF: 'Descargar como PDF',
  printChart: 'Imprimir',
  noData: 'No hay información para mostrar',
  contextButtonTitle: 'Opciones de Exportación',
  loading: 'Cargando Información'
}


const SpanishRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 de ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} de ${length}`;
}


export function getSpanishPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Registros por página:';
  paginatorIntl.nextPageLabel = 'Página siguiente';
  paginatorIntl.previousPageLabel = 'Página Anterior';
  paginatorIntl.getRangeLabel = SpanishRangeLabel;
  paginatorIntl.firstPageLabel = 'Al Principio';
  paginatorIntl.lastPageLabel = 'Al Final';

  return paginatorIntl;
}