import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

@Injectable()
export class HttpFilter implements HttpInterceptor {

    constructor(private session: SessionService){}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addAuthentication(request));
    }
    private addAuthentication(request: HttpRequest<any>): HttpRequest<any> {
      if (request.url.match(/api\//) && request.url.includes('/authorize')) {
            const token =  this.session.getApiToken;
            if (token) {
                request = request.clone({
                    setHeaders: {Authorization: 'Bearer ' + token},
                    url: request.url.replace('/authorize', '')
                });
            }
        }
        return request;
    }
}