import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Empresa } from 'src/app/global/model/Model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  Cargando = false;
  EmpresaActual: Empresa = new Empresa();
  chartBar: any;

  constructor(private session: SessionService,
              private auth: AuthenticationService,
              private router: Router) { }

  ngOnInit() {
    this.session.EmpresaActual.subscribe(empresa=>{
      this.EmpresaActual = empresa;
    });

    this.auth.currentUser.subscribe(user =>{
      if(user === null)
        this.router.navigate(['/']);
    });
      
  }

}
