import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Recepcion } from 'src/app/global/model/Model';

@Component({
  selector: 'app-detalle-factura',
  templateUrl: './detalle-factura.component.html',
  styleUrls: ['./detalle-factura.component.css']
})
export class DetalleFacturaComponent implements OnInit, OnChanges {
  @Input() Detalle: any[];
  displayedColumns: string[] = ['Producto'];
  PrecioTotalSolicitado: string;
  PrecioTotalDespacho: string;
  PrecioTotalMerma: string;
  PrecioTotalRecepcion: string;
  constructor() { }

  ngOnInit() {
    
  }

  ngOnChanges(){
    if(this.Detalle != undefined){
      this.PrecioTotalDespacho =  (this.Detalle.reduce((sum, a) => {return sum + a.TotalDespachado }, 0 )) 
      this.PrecioTotalMerma =  (this.Detalle.reduce((sum, a) => { return sum + a.TotalMermado}, 0 )) 
      this.PrecioTotalRecepcion =  (this.Detalle.reduce((sum, a) => { return sum + a.TotalRecepcionado}, 0)) 

    }
  }

}
