import { NumberSymbol } from '@angular/common';
import { ChartParallelAxesDateTimeLabelFormatsDayOptions } from 'highcharts';
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { Estado, Privilegio, TipoEmpresa, TipoUsuario } from './Enumerados';

export class Usuario {
    Id: number;
    Nombre: string;
    Apellido: string;
    Cedula: string;
    Mail: string;
    Telefono: string;
    DebeCambiarClave: boolean;
    UsuarioEmpresa: UsuarioEmpresa[];
    Token: string;
    RefreshTokenWeb: string;
    Documentos: Documento[];

    NombreCompleto: string;

    get Iniciales(): string {
        return this.Id > 0 ? this.Nombre.substr(0, 1) + this.Apellido.substr(0, 1) : "";
    }

    constructor() {
        this.NombreCompleto = this.Nombre + ' ' + this.Apellido;
    }
}

export class DatosConexion {
    IdUsuario: number;
    Cedula: string;
    ApiToken: string;
    RefreshToken: string;
    DeviceId: string;
    TipoUsuario: TipoUsuario;
    Pass: string;
    NombreUsuario: string;
    constructor() {
        this.TipoUsuario = TipoUsuario.Web
    }
}

export class UsuarioEmpresa {
    Id: number;
    IdUsuario: number;
    Usuario: Usuario;
    IdEmpresa: number;
    Empresa: Empresa;
    TipoUsuario: TipoUsuario;
    FechaCreacion: Date;
    AceptaEmpresa: boolean;
    AceptaUsuario: boolean;
    EsAdmin: boolean;

    Privilegios: PrivilegiosUsuarioEmpresa[];
}

export class Empresa {
    Id: number;
    Tipo: TipoEmpresa;
    Identificador: string;
    RazonSocial: string;
    Giro: string;
    Direccion: string;
    Mail: string;
    FechaRegistro: Date;
    EsVerificado: boolean;

    Telefonos: Telefono[];
    EmpresaProveedor: EmpresaProveedor[];
    Pedidos: Pedido[];
    UsuarioEmpresa: UsuarioEmpresa[];
    Documentos: Documento[];
}

export class PrivilegiosUsuarioEmpresa {
    IdUsuarioEmpresa: number;
    IdPrivilegio: Privilegio;
    FechaCreacion: Date;
    IdUsuarioModifica: number;
}

export class Documento {
    Id: number;
    IdUsuario: number;
    IdEmpresa: number;
    Imagen: string;
    FechaVigencia: Date;
}

export class EmpresaProveedor {
    Id: number;
    IdEmpresaSolicita: number;
    EmpresaSolicita: Empresa;
    IdProveedor: number;
    Proveedor: Empresa;
    Eliminado: boolean;
}

export class Pedido {
    Id: number;
    IdDespacho: number;
    Despacho: Despacho;
    IdEmpresaOrigen: number;
    EmpresaOrigen: Empresa;
    IdEmpresaDestino: number;
    EmpresaDestino: Empresa;
    IdUsuarioRegistra: number;
    UsuarioRegistra: Usuario;
    FechaCreacion: Date;
    get FromNow(): string { return "moment.no" }

    Estado: Estado
    Eliminado: boolean;
    Items: ItemPedido[];
}

export class Telefono {
    Id: number;
    IdUsuario: number;
    IdEmpresa: number;
    Numero: string;
    Eliminado: boolean;
}

export class Despacho {
    Id: number;
    IdPedidoOrigen: number;
    IdEmpresaOrigen: number;
    EmpresaOrigen: Empresa;
    IdEmpresaDestino: number;
    EmpresaDestino: Empresa;
    IdUsuarioDestino: number;
    UsuarioDestino: Usuario;
    IdUsuarioRegistra: number;
    UsuarioRegistra: Usuario;
    FechaCreacion: Date;
    Eliminado: boolean;
    Items: ItemDespacho[];
}

export class ItemPedido {
    Id: number;
    IdEmpresaOrigen: number;
    EmpresaOrigen: Empresa;
    IdEmpresaDestino: number;
    EmpresaDestino: Empresa;
    IdPedido: number;
    Pedido: Pedido;
    IdProducto: number;
    Producto: Producto;
    Descripcion: string;
    UnidadMedida: string;
    Cantidad: number;
    Precio: string;
    FechaCreacion: Date;
    Eliminado: number;

}

export class Producto {
    Id: number;
    Nombre: string;
    Icono: string;
    IdUnidad: number;
    Unidad: Unidad;
    FactorConversion: number;
    Eliminado: boolean;
}

export class Unidad {
    Id: number;
    Descripcion: string;
    Eliminado: boolean;

}

export class ItemDespacho {
    Id: number;
    IdEmpresaOrigen: number;
    EmpresaOrigen: Empresa;
    IdEmpresaDestino: number;
    EmpresaDestino: Empresa;
    IdDespacho: number;
    Despacho: Despacho;
    IdProducto: number;
    Producto: Producto;

    Descripcion: string;
    UnidadMedida: string;
    Cantidad: number;
    Precio: string;
    Peso: number;
    FechaCreacion: Date;
    Eliminado: boolean;
}

export class ProductoEmpresa {
    Id: number;
    IdProducto: number;
    Producto: Producto;
    IdEmpresa: number;
    Empresa: Empresa;
    Cantidad: number;
    Peso: number;
    FechaCreacion: Date;
    Eliminado: boolean;
}

export class Recepcion {
    Id: number;
    IdEmpresaRecepcion: number;
    EmpresaRecepcion: Empresa;
    IdEmpresaDespacho: number;
    EmpresaDespacho: Empresa;
    IdDespacho: number;
    Despacho: Despacho;
    IdUsuarioRegistra: number;
    UsuarioRegistra: Usuario;
    PrecioTotal: number;
    PrecioTotalMerma: number;
    FechaCreacion: Date;
    Eliminado: boolean;
    Items: ItemRecepcion[];
    Mermas: Merma[];
    Pedido: Pedido;
}

export class ItemRecepcion{
    Id: number;
    IdEmpresaRecepcion: number;
    EmpresaRecepcion: Empresa;
    IdEmpresaDespacho: number;
    EmpresaDespacho: Empresa;
    IdRecepcion: number;
    Recepcion: Recepcion;
    IdProducto: number;
    Producto: Producto;
    Descripcion: string;
    UnidadMedida: string;
    Cantidad: number;
    Precio: number;
    PrecioTotal: number;
    Peso: number;
    TieneMerma: boolean;
    FechaCreacion: Date;
    Eliminado: boolean;
    // ItemRecepcionBodega: ItemRecepcionBodega[];
    // PesoUnitario: PesoUnitario[];
}

export class Merma{
    Id: number;
    IdRecepcion: number;
    Recepcion: Recepcion;
    IdProducto: number;
    Producto: Producto;
    Motivo: string;
    Peso: number;
    Cantidad: number;
    Precio: number;
    PrecioTotal: number;
    FechaCreación: Date;
    Eliminado: boolean;
}



export class Filtro {
    Series: string[];
    IdEmpresaDespacho: number;
    IdEmpresaRecepcion: number;
    FechaInicio: number;
    FechaTermino: number;
    Anho: number;
    Mes: string;
    Tipo: string;
    TipoFactura: string;
    constructor() {
        this.Series = [];
        this.IdEmpresaDespacho = 0;
        this.IdEmpresaRecepcion = 0;
        this.FechaInicio = 0;
        this.FechaTermino = 0;
        this.Mes = "";
        this.Anho = 0;
        this.Tipo = 'Compradas';
        
    }
}