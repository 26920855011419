import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';


import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule, MatButtonToggleModule, MatInputModule, MatSelectModule, MatButtonModule, MatToolbarModule, 
         MatMenuModule, MatIconModule, MatGridListModule, MatSidenavModule, MatExpansionModule, 
         MatCardModule, MatStepperModule, MatListModule, MatProgressBarModule,MatSlideToggleModule, MatDatepickerModule, MatSnackBarModule } from '@angular/material';
import { NgxEditorModule } from 'ngx-editor';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ExcelService } from './global/Services/excel.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home/home.component';

import { HeaderComponent } from './home/header/header.component';
import { FooterComponent } from './home/footer/footer.component';
import { AppRoutingModule } from './/app-routing.module';

import { CalendarModule } from 'primeng/calendar'
import { ProgressComponent } from './global/progress/progress.component';
import { DialogComponent } from './global/dialog/dialog.component';
import { getSpanishPaginatorIntl } from './global/model/global';
import { DateFormatPipe } from './global/Services/date-format.pipe';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PolicyComponent } from './home/policy/policy.component';
import { LoginComponent } from './widget/login/login.component';
import { DashboardComponent } from './private/dashboard/dashboard.component';
import { ConsolidadoComponent } from './private/dashboard/consolidado/consolidado.component';

import { ChartTopVentasComponent } from './private/dashboard/chart-top-ventas/chart-top-ventas.component';
import { HttpFilter } from './services/HttpFilter.interceptor';
import { HttpErrorFilter } from './services/HttpErrorFilter.interceptor';
import { ToPdfFacturaComponent } from './private/listado/to-pdf-factura/to-pdf-factura.component';
import { DetalleFacturaComponent } from './private/listado/detalle-factura/detalle-factura.component';
import { PedidoComponent } from './private/listado/pedido/pedido.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FiltroComponent } from './private/filtro/filtro.component';
import { ListadoComponent } from './private/listado/listado.component';
import { DespachoComponent } from './private/listado/despacho/despacho.component';
import { RecepcionComponent } from './private/listado/recepcion/recepcion.component';
import { FacturaComponent } from './private/listado/factura/factura.component';
import { PagoComponent } from './private/listado/pago/pago.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  declarations: [
    AppComponent,
    ChartTopVentasComponent,
    ConsolidadoComponent,
    DashboardComponent,
    DateFormatPipe,
    DespachoComponent,
    DetalleFacturaComponent,
    DialogComponent,
    FacturaComponent,
    FiltroComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    ListadoComponent,
    LoginComponent,
    PagoComponent,
    PedidoComponent,
    PolicyComponent,
    ProgressComponent,
    RecepcionComponent,
    ToPdfFacturaComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CalendarModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMomentDateModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    NgxEditorModule,
    ReactiveFormsModule,
    TooltipModule.forRoot()
  ],
  providers: [AppComponent,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    ExcelService,
    DateFormatPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorFilter, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpFilter, multi: true }
  ],

  bootstrap: [AppComponent],
  entryComponents: [DialogComponent]
})
export class AppModule { }
