import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css']
})
export class PedidoComponent implements OnInit {
  @Input() Seleccionado: any;
  constructor() { }

  ngOnInit() {
    
  }

}