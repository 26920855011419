import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartBarStacked, SerieBarStacked } from 'src/app/global/model/ModelDashboard';
import { Filtro } from 'src/app/global/model/Model';
import { DateFormatPipe } from 'src/app/global/Services/date-format.pipe';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import * as Global from '../../../global/model/global';

@Component({
    selector: 'app-chart-top-ventas',
    templateUrl: './chart-top-ventas.component.html',
    styleUrls: ['./chart-top-ventas.component.css']
})
export class ChartTopVentasComponent implements OnInit, OnDestroy {
    @Output() talk: EventEmitter<any> = new EventEmitter<any>();
    @Input() Data: ChartBarStacked;
    list: any;
    total: number;
    options: any;

    Filtro = new Filtro();

    subscriptionFiltro: Subscription;

    constructor(private session: SessionService,
                private service: ApiService) { }

    ngOnInit() {
        if (this.Data == null) {
            this.Data = new ChartBarStacked();
        }
        this.subscriptionFiltro = this.session.Filtro.subscribe(filtro => {
            if (filtro != null) {
                this.Filtro = filtro;
                this.getData();
            }
        });
    }

    ngOnDestroy(){
        this.subscriptionFiltro.unsubscribe();
    }

    getData() {
        let path = [];
        path.push(`dashboard/topventa/`);
        path.push(`FechaInicio=${this.Filtro.FechaInicio};`);
        path.push(`FechaTermino=${this.Filtro.FechaTermino};`);

        path.push(`IdEmpresaDespacho=${this.Filtro.IdEmpresaDespacho};`);
        path.push(`IdEmpresaRecepcion=${this.Filtro.IdEmpresaRecepcion};`);

        this.service.Get(path.join(""), true)
            .pipe(first())
            .subscribe(top => {
                this.Data = top
                this.generarGrafico();

            }, error => {
                this.generarGrafico();

            });
    }

    generarGrafico() {
        this.options = {
            exporting: Global.exporting,
            lang: Global.lang,
            chart: {
                type: 'bar',
                height: 330,
                // events:{
                //     load: function () {
                //         this.showLoading();
                //     }
                // }
            },
            title: {
                text: this.Data.Titulo
            },
            subtitle: {
                text: this.Data.Subtitulo
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: this.Data.categorias,
                labels: {
                    style: {
                        fontWeight: '600'
                    }
                }
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: `Dolares / Libras `//${this.Tipo}`
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            series: this.Data.series,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        }
        // Highcharts.chart('canvasTopExport', options);
        Highcharts.chart('canvasTop', this.options);
    }



    showTable() {
        this.list = [];
        for (var j = 0; j < this.Data.categorias.length; j++) {
            this.list.push({ 'cat': this.Data.categorias[j], 'cant': this.Data.series[0].data[j] });
        }
        this.total = this.list.map(a => a.cant).reduce(function (a, b) {
            return a + b;
        });

    }
    toPDF() {
        this.talk.emit('imprimir')
    }





    sortChart() {
        var chart: any = Highcharts.chart('canvasTop', this.options);

        var points = chart.series[0].points,
            labelX,
            ticks = chart.xAxis[0].ticks;

        var lista = [];

        chart.series.forEach(element => {
            var indice = this.Filtro.Series.findIndex(cat => cat === element.name)
            if (indice === -1) {
                element.data.forEach(dataSerie => {
                    var a = lista.findIndex(cat => cat[0] === dataSerie.category)

                    if (a === -1) {
                        lista.push([dataSerie.category, dataSerie.y])
                    } else {
                        lista[a][1] += dataSerie.y
                    }
                });
            }
        });
        lista = lista.sort(function (a, b) {
            return b[1] - a[1];
        });

        //agregar points ordenados según array
        var sortedPoints = [];
        for (var i = 0; i < lista.length; i++) {
            var indicePoint = points.findIndex(p => p.category === lista[i][0])
            sortedPoints.push(points[indicePoint])
        }

        points.forEach(function (point, i) {
            sortedPoints.forEach(function (sPoint, j) {
                if (point === sPoint) {

                    labelX = chart.plotWidth - points[i].plotY + chart.plotLeft;

                    // Animate the column
                    points[i].graphic.animate({
                        x: points[j].shapeArgs.x
                    });

                    ticks[i].label.animate({
                        y: ticks[j].label.xy.y
                    });
                }
            });
        });
    }
}