import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Empresa, Filtro } from 'src/app/global/model/Model';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SessionService } from 'src/app/services/session.service';
import { DateFormatPipe } from 'src/app/global/Services/date-format.pipe';
import { MatStepper } from '@angular/material';
import { PagoComponent } from './pago/pago.component';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('pago') pago: PagoComponent;

  EmpresaActual: Empresa = new Empresa();
  Filtro = new Filtro();
  ListaPedidos: any;
  Seleccionado: any;
  subscriptionFiltro: Subscription;
  Cargando: boolean;
  bgColor = "";

  constructor(private session: SessionService,
    private auth: AuthenticationService,
    private router: Router,
    private service: ApiService,
    private pipe: DateFormatPipe) {

      // this.stepper = new MatStepper(undefined, undefined);
     }

  ngOnInit() {
    this.session.EmpresaActual.subscribe(empresa => {
      this.EmpresaActual = empresa;
      switch (empresa.Tipo) {
        case 1:
          this.bgColor = "#FFF635";
          break;
        case 2:
          this.bgColor = "#5CB900";
          break;
        case 3:
          this.bgColor = "#FF9E00";
          break;
      }
    });

    this.auth.currentUser.subscribe(user => {
      if (user === null)
        this.router.navigate(['/']);
    });

    this.subscriptionFiltro = this.session.Filtro.subscribe(filtro => {
      if (filtro != null) {
        this.Filtro = filtro;
        this.getListaPedidos();
      }
    });
  }

  getListaPedidos() {
    this.Cargando = true;
    this.Seleccionado = undefined;
    let path = [];

    path.push(`reporte/listapedidos/`);
    path.push(`FechaDesde=${this.Filtro.FechaInicio};`);
    path.push(`FechaHasta=${this.Filtro.FechaTermino};`);
    path.push(`IdEmpresaOrigen=${this.Filtro.IdEmpresaDespacho};`);
    path.push(`IdEmpresaDestino=${this.Filtro.IdEmpresaRecepcion};`);

    this.service.Get(path.join(""), true)
      .pipe(first())
      .subscribe(listaPedidos => {
        this.ListaPedidos = listaPedidos;
        this.Seleccionado = listaPedidos[0];
        this.Seleccionado = undefined;
        this.Cargando = false;
      }, error => {
        this.Cargando = false;
      })
  }

  onPedidoSelected(pedido, accion = '') {
    this.Seleccionado = pedido;
    this.session.setSeleccionado(pedido);
    this.getDespacho();
    this.getRecepcion();
    this.getFactura();
    this.getPago();
    if (this.stepper) {
      switch (accion) {
        case 'f':
          this.stepper.selectedIndex = 3;
          // this.stepChanged({ selectedIndex: 3 });
          break;
        case 'p':
          this.stepper.selectedIndex = 4;
          // this.stepChanged({ selectedIndex: 4 });
          break;
        default:
          this.stepper.selectedIndex = 0;
          break;
      }
    }
  }

  stepChanged(event) {
    switch (event.selectedIndex) {
      case 1:
        this.getDespacho();
        break;
      case 2:
        this.getRecepcion();
        break;
      case 3:
        this.getFactura();
        break;
      case 4: {
        this.getFactura();
        this.getPago();
      }
        break;
    }
  }

  getDespacho() {
    if (this.Seleccionado.IdDespacho > 0) {
      if (!this.Seleccionado.Despacho) {
        let path = [];
        path.push(`abastecimiento/despacho/`);
        path.push(`Id=${this.Seleccionado.IdDespacho};`);
        path.push(`Includes=Items.Producto;`);
        this.service.Get(path.join(""), true)
        .pipe(first())
        .subscribe(despacho => {
          this.Seleccionado.Despacho = despacho[0];
          this.Cargando = false;
        }, error => {
          this.Cargando = false;
        })
      }
    }
  }

  getRecepcion() {
    if (this.Seleccionado.IdRecepcion > 0) {
      if (!this.Seleccionado.Recepcion) {
        let path = [];
        path.push(`abastecimiento/recepcion/`);
        path.push(`Id=${this.Seleccionado.IdRecepcion};`);
        path.push(`Includes=Items.Producto;`);
        this.service.Get(path.join(""), true)
        .pipe(first())
        .subscribe(recepcion => {
          this.Seleccionado.Recepcion = recepcion[0];
          this.Cargando = false;
        }, error => {
          this.Cargando = false;
        });
      }
    }
  }

  getFactura() {
    if (this.Seleccionado.IdFactura > 0) {
      if (!this.Seleccionado.Factura) {
        let path = [];
        path.push(`administracion/factura/`);
        path.push(`Id=${this.Seleccionado.IdFactura};`);
        path.push(`Includes=Detalles;`);
        this.service.Get(path.join(""), true)
        .pipe(first())
        .subscribe(factura => {
          this.Seleccionado.Factura = factura[0];
          this.Cargando = false;
        }, error => {
          this.Cargando = false;
        });

      }
    }
  }

  getPago() {
    if (this.Seleccionado.IdPago > 0) {
      if (!this.Seleccionado.Pago || !this.Seleccionado.Pago.Id ) {
        this.getFactura();
        let path = [];
        path.push(`administracion/pago/`);
        path.push(`IdFactura=${this.Seleccionado.IdPago};`);
        path.push(`Includes=Detalles;`);
        this.service.Get(path.join(""), true)
        .pipe(first())
        .subscribe(pago => {
          this.Seleccionado.Pago = pago[0];
          this.pago.TotalAcumulado = pago[0].Detalles.reduce((sum, a) => { return sum + a.Monto }, 0)
          this.pago.SaldoPendiente = this.Seleccionado.Factura ? this.Seleccionado.Factura.PrecioTotal - this.pago.TotalAcumulado : 0;
          this.Cargando = false;
        }, error => {
          this.Cargando = false;
        });
      }
    }
  }

  
}
