import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { pipe } from '@angular/core/src/render3';
import { Router } from '@angular/router';
import { DatosConexion } from 'src/app/global/model/Model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  Cargando: boolean;
  LoginModel: DatosConexion;
  constructor(private auth: AuthenticationService,
              private router: Router) {
    this.LoginModel = new DatosConexion();
  }

  ngOnInit() {
  }

  OnLoginRequest() {
    this.Cargando = true;
    this.auth.login(this.LoginModel)
    .pipe(first())
      .subscribe(data => {
        document.getElementById('closeLoginModal').click();
        this.router.navigate(['/privado/dashboard'])

        this.Cargando = false;
      });
  }
}
