import { Injectable } from '@angular/core';
import { Empresa, EmpresaProveedor } from '../global/model/Model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Filtro } from '../global/model/Model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public static EMPTY = new Object();

  public EmpresaActualSubject: BehaviorSubject<Empresa>;
  public EmpresaActual: Observable<Empresa>;

  public RefreshTokenSubject: BehaviorSubject<string>;
  public RefreshToken: Observable<string>;

  public ApiTokenSubject: BehaviorSubject<string>;
  public ApiToken: Observable<string>;

  public FiltroSubject: BehaviorSubject<Filtro>;
  public Filtro: Observable<Filtro>;

  public ProveedoresSubject: BehaviorSubject<EmpresaProveedor[]>;
  public Proveedores: Observable<EmpresaProveedor[]>;

  public CompradoresSubject: BehaviorSubject<EmpresaProveedor[]>;
  public Compradores: Observable<EmpresaProveedor[]>;

  public SeleccionadoSubject: BehaviorSubject<any>;
  public Seleccionado: Observable<any>

  constructor() {
    this.EmpresaActualSubject = new BehaviorSubject<Empresa>(JSON.parse(localStorage.getItem('EmpresaActual')));
    this.EmpresaActual = this.EmpresaActualSubject.asObservable();

    this.RefreshTokenSubject = new BehaviorSubject<string>(localStorage.getItem('RefreshToken'));
    this.RefreshToken = this.RefreshTokenSubject.asObservable();

    this.ApiTokenSubject = new BehaviorSubject<string>(localStorage.getItem('ApiToken'));
    this.ApiToken = this.ApiTokenSubject.asObservable();

    this.FiltroSubject = new BehaviorSubject<Filtro>(JSON.parse(localStorage.getItem('Filtro')));
    this.Filtro = this.FiltroSubject.asObservable();

    this.ProveedoresSubject = new BehaviorSubject<EmpresaProveedor[]>(JSON.parse(localStorage.getItem('Proveedores')));
    this.Proveedores = this.ProveedoresSubject.asObservable();

    this.CompradoresSubject = new BehaviorSubject<EmpresaProveedor[]>(JSON.parse(localStorage.getItem('Compradores')));
    this.Compradores = this.CompradoresSubject.asObservable();

    this.SeleccionadoSubject = new BehaviorSubject<any>('');
    this.Seleccionado = this.SeleccionadoSubject.asObservable();
  }

  setEmpresaActual(empresa: Empresa) {
    localStorage.setItem('EmpresaActual', JSON.stringify(empresa));
    this.EmpresaActualSubject.next(empresa);
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('RefreshToken', refreshToken);
    this.RefreshTokenSubject.next(refreshToken);
  }

  setApiToken(apiToken: string) {
    localStorage.setItem('ApiToken', apiToken);
    this.ApiTokenSubject.next(apiToken);
  }

  setFiltro(filtro: Filtro){
    localStorage.setItem('Filtro', JSON.stringify(filtro));
    this.FiltroSubject.next(filtro);
  }

  setProveedores(proveedores: EmpresaProveedor[]){
    localStorage.setItem('Proveedores', JSON.stringify(proveedores));
    this.ProveedoresSubject.next(proveedores);
  }

  setCompradores(compradores: EmpresaProveedor[]){
    localStorage.setItem('Compradores', JSON.stringify(compradores));
    this.CompradoresSubject.next(compradores);
  }

  setSeleccionado(seleccionado: any){
    this.SeleccionadoSubject.next(seleccionado);
  }

  public get getEmpresaActual(): Empresa {
    return this.EmpresaActualSubject.value;
  }

  public get getRefreshToken(): string {
    return this.RefreshTokenSubject.value;
  }

  public get getApiToken(): string {
    return this.ApiTokenSubject.value;
  }

  public get getFiltro(): Filtro {
    return this.FiltroSubject.value;
  }

  public get getProveedores(): EmpresaProveedor[]{
    return this.ProveedoresSubject.value;
  }

  public get getCompradores(): EmpresaProveedor[]{
    return this.CompradoresSubject.value;
  }

  public get getSeleccionado(): any {
    return this.SeleccionadoSubject.value;
  }

  clear() {
    localStorage.removeItem('ApiToken');
    localStorage.removeItem('RefreshToken');
    localStorage.removeItem('EmpresaActual');
    localStorage.removeItem('Proveedores');
    localStorage.removeItem('Compradores');
    localStorage.removeItem('Filtro');

    this.ApiTokenSubject.next(null);
    this.RefreshTokenSubject.next(null);
    this.EmpresaActualSubject.next(null);
    this.ProveedoresSubject.next(null);
    this.CompradoresSubject.next(null);
    this.FiltroSubject.next(null);
    this.SeleccionadoSubject.next(null);
  }
}
