import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatosConexion, Empresa, Usuario } from '../global/model/Model';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as Global from '../global/model/global';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<Usuario>;
  public currentUser: Observable<Usuario>;

  constructor(private http: HttpClient,
              private session: SessionService,
              private router: Router) {
    this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Usuario {
    return this.currentUserSubject.value;
  }

  public login(Login: DatosConexion){
    return this.http.post<Usuario>(`${Global.ApiUrl}login/usuario`, Login)
    .pipe(map(usuario => {
        // login successful if there's a jwt token in the response
        if (usuario && usuario.Token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          
            localStorage.setItem('currentUser', JSON.stringify(usuario));
            this.currentUserSubject.next(usuario);
            this.session.setEmpresaActual(usuario.UsuarioEmpresa[0].Empresa)
            this.session.setRefreshToken(usuario.RefreshTokenWeb)
            this.session.setApiToken(usuario.Token);
            return usuario;
        }
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.session.clear();
    this.router.navigate(['/']);
  }



  // cambiar(login: Usuario) {
  //   return this.http.post<any>(`${Global.apiUrl}login/cambiar-pass`, login, Global.httpPostOptions)
  //     .pipe(map(usuario => {
  //       return usuario;
  //     }));
  // }

  refreshToken():Observable<string> {
    let payload = { 'ApiToken': this.session.getApiToken, 'RefreshToken': this.session.getRefreshToken }
    return this.http.post<Usuario>(`${Global.ApiUrl}/login/usuario/refresh`, payload, Global.httpPostOptions)
      .pipe(map(usuario => {
        // login successful if there's a jwt token in the response
        if (usuario && usuario.Token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('currentUser', JSON.stringify(usuario));
          this.session.setRefreshToken(usuario.RefreshTokenWeb)
          this.session.setApiToken(usuario.Token);
          // this.currentUserSubject.next(usuario);
        }
        return usuario.Token;
      }));
  }
}
