import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private matIconResgistry: MatIconRegistry,
              private domSanitizer: DomSanitizer){
    this.matIconResgistry.addSvgIcon("FeatureDistribuidor",this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/icon_1.svg"));
    this.matIconResgistry.addSvgIcon("FeatureProductor",this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/icon_2.svg"));
    this.matIconResgistry.addSvgIcon("FeatureCliente",this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/Feature-Cliente.svg"));
  }
  title = 'FincappWeb';
}
