import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Empresa } from 'src/app/global/model/Model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.css']
})
export class FacturaComponent implements OnInit, OnChanges {
  @Input() Seleccionado: any;
  @Input() Empresa: Empresa;
  Folio: number;
  isLoading = false;
  constructor(private service: ApiService) { }

  ngOnInit() {

  }

  ngOnChanges() {
    this.getFolio();
  }

  getFolio() {
    let path = [];
    path.push(`administracion/factura/folio/${this.Empresa.Id}`);

    this.service.Get(path.join(""), true)
      .pipe(first())
      .subscribe(folio => {
        this.Folio = folio;
      }, error => {
      });
  }

  facturar(){
    this.isLoading = true;
    let path = [];
    path.push(`administracion/factura`);

    let factura = { 'Folio': this.Folio, 'IdRecepcion': this.Seleccionado.IdRecepcion };

    this.service.Post(factura, path.join(""), true)
    .pipe(first())
    .subscribe(factura => {
      this.Seleccionado.IdFactura = factura.Id;
      this.Seleccionado.Factura = factura;
      this.Seleccionado.Pago = {
        IdFactura: factura.Id,
        Detalles: [],
        Estado: 1,
        SaldoPendiente: factura.PrecioTotal
      }
      this.isLoading = false;
    }, error =>{
      this.isLoading = false;
    });
  }
}