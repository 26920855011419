export class ChartBarStacked {
    Titulo: string;
    Subtitulo: string;
    categorias: string[];
    series: SerieBarStacked[];
    constructor() {
        this.Titulo = 'Productos más Vendidos';
        this.series = [];
        this.categorias = [];
        // for (var i = 5; i > 0; i--) { this.Categorias.push('') }
        for (var i = 1; i > 0; i--) { this.series.push(new SerieBarStacked('', '', 1)) }
    }
}

export class SerieBarStacked {
    name: string;
    data: number[];
    color: string;

    constructor(name: string, color: string, lenght: number = 0) {
        this.name = name;
        this.color = color;
        this.data = [];
        if (lenght != 0) {
            for (var i = lenght; i > 0; i--) { this.data.push(0) }
        }
    }
}